import React from "react"
import PropTypes from "prop-types"
import linkedinIcon from "./linkedin_icon.svg"
import twitterIcon from "./tweet_icon.svg"
import styled from "styled-components"

const ShareIcon = styled.a`
  height: 24px;
  width: 24px;
`

const ShareIcons = props => {
  const styles = {
    display: "flex",
    width: 120,
    justifyContent: "space-between"
  }

  const handleFacebookClick = event => {
    event.preventDefault()
    const anchor = event.target.closest("a")
    window.open(
      anchor.href,
      "",
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600"
    )
  }

  const handleLinkedInClick = event => {
    event.preventDefault()
    const anchor = event.target.closest("a")
    window.open(
      anchor.href,
      "",
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
    )
  }

  const handleTwitterClick = event => {
    event.preventDefault()
    const anchor = event.target.closest("a")
    window.open(
      anchor.href,
      "",
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
    )
  }

  const shareUrl = encodeURIComponent(props.url)
  const shareTitle = encodeURIComponent(props.title)

  return (
    <div style={styles}>
      <ShareIcon
        href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&t=${shareTitle}`}
        target="_blank"
        rel="noreferrer"
        onClick={handleFacebookClick}
      >
        <svg
          width="23"
          height="23"
          viewBox="0 0 23 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_2268_934)">
            <rect width="22.8802" height="22.3588" fill="white" />
            <path
              d="M15.7619 22.3588V13.6637H18.6855L19.0669 10.3099H15.6348V8.19821C15.6348 7.20449 15.8891 6.58341 17.2873 6.58341H19.0669V3.60224C18.1771 3.47803 17.2873 3.47803 16.3975 3.47803C14.1095 3.35381 12.0757 4.96861 11.9486 7.20449C11.9486 7.45292 11.9486 7.70135 11.9486 7.94978V10.4341H9.27921V13.6637H12.2028V22.3588H1.27112C0.50845 22.3588 0 21.8619 0 21.1166V1.24215C0 0.496861 0.50845 0 1.27112 0H21.6091C22.3718 0 22.8802 0.496861 22.8802 1.24215V21.1166C22.8802 21.8619 22.3718 22.3588 21.6091 22.3588H15.7619Z"
              fill="#0C9EFF"
            />
          </g>
          <defs>
            <clipPath id="clip0_2268_934">
              <rect width="22.8802" height="22.3588" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </ShareIcon>
      <ShareIcon
        href={`https://www.linkedin.com/cws/share?url=${shareUrl}`}
        target="_blank"
        rel="noreferrer"
        onClick={handleLinkedInClick}
      >
        <img src={linkedinIcon} alt="Share on LinkedIn" />
      </ShareIcon>
      <ShareIcon
        href={`https://twitter.com/share?text=${shareTitle}&url=${shareUrl}`}
        onClick={handleTwitterClick}
      >
        <img src={twitterIcon} alt="Share on Twitter" />
      </ShareIcon>
    </div>
  )
}

ShareIcons.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
}

export default ShareIcons
