import React from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  doesTagsContainNoIndex,
  getHTMLLang,
  getLocaleLanguageCode,
  localizedPath,
  stringReplace
} from "../../utilities/utilities"
import Layout from "../../components/Layout/Layout"
import Template from "../../components/Template/Template"
import { renderTags } from "../../utilities/renderTags"
import {
  getArticlePath,
  getBlogCategoryPath
} from "../../utilities/blogUtilities"
import { Container, Row, Col } from "react-bootstrap"
import t from "../../translations"
import SEO from "../../components/seo"
import ProgressBar from "../../components/ProgressBar"
import BlogPost from "./BlogPost"
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb"

import styles from "./Article.module.scss"

import getPageData from "../../analytics/getPageData"
import { useScrollTracking } from "../../analytics/useScrollTracking"
import { usePageTracking } from "../../analytics/usePageTracking"
import { isValidImage } from "../../utilities/isValidImage"
import getValueOrDefault from "../../analytics/getValueOrDefault"
import { categorySchema } from "./categorySchema"

export const query = graphql`
  query ($pageId: String!, $id: String!, $locale: String!) {
    contentfulPage(id: { eq: $pageId }) {
      ...pageFields
    }
    site {
      siteMetadata {
        defaultLocale
        siteUrl
      }
    }
    contentfulBlogPost(id: { eq: $id }, node_locale: { eq: $locale }) {
      id
      slug
      node_locale
      metaTitle
      metaDescription {
        metaDescription
      }
      category {
        styleCategoryBlogPost
      }
      segmentUpdatedAt: updatedAt(formatString: "yyyyMMDD")
      updatedOnSchemaDate: updatedAt(formatString: "yyyy-MM-DD")
      segmentPublishedAt: datePublished(formatString: "yyyyMMDD")
      title
      updatedAt
      dateUpdated: updatedAt(formatString: "DD MMM YYYY", locale: $locale)
      datePublishedPropContent: datePublished
      datePublished(formatString: "DD MMM YYYY", locale: $locale)
      publishedOnSchemaDate: datePublished(formatString: "yyyy-MM-DD")
      body {
        childMarkdownRemark {
          html
          timeToRead
        }
      }
      author {
        ...authorFields
      }
      category {
        ...blogPostCategoryShortFields
        schemaScripts {
          ...contentfulScriptTagFields
        }
      }
      mainImage {
        ...assetFields
        gatsbyImageData(layout: CONSTRAINED, width: 650, quality: 100)
      }
      readTime
      extraTags {
        ...extraTagFields
      }
      relatedBlogPosts {
        ...relatedBlogPostFields
      }
    }
  }
`

const Article = props => {
  useScrollTracking()

  const { localeLinks, locale, defaultLocale } = props.pageContext

  const template = props.data.contentfulPage.template
  const page = props.data.contentfulPage
  const blogPost = props.data.contentfulBlogPost
  const blogCategoryStyle = _.get(
    props,
    "data.contentfulBlogPost.category.styleCategoryBlogPost"
  )

  const categoryPath = getBlogCategoryPath(
    blogPost.category,
    1,
    locale,
    defaultLocale
  )

  usePageTracking({
    article_category: getValueOrDefault(
      blogPost.category.analyticalName,
      blogPost.category.slug
    )
  })

  const breadcrumb = [
    {
      id: localizedPath("", null, locale, defaultLocale),
      title: t.translate(t.KEYS.HOME, locale),
      url: localizedPath("", null, locale, defaultLocale),
      hideOnSmallDevices: true
    },
    {
      id: localizedPath("blog", null, locale, defaultLocale),
      title: "Blog",
      url: localizedPath("blog", null, locale, defaultLocale),
      hideOnSmallDevices: true
    },
    {
      id: categoryPath,
      title: blogPost.category.name,
      url: categoryPath
    },
    {
      id: props.pageContext.pageUrl,
      title: blogPost.title,
      url: null,
      hideOnSmallDevices: true
    }
  ]

  // function getWordCount($contentElement) {
  //   const text = $contentElement.textContent
  //   const words = text.split(" ")
  //   return words.length
  // }

  function renderBody() {
    return (
      <BlogPost
        blogPost={blogPost}
        pageUrl={pageUrl}
        locale={locale}
        defaultLocale={defaultLocale}
        blogCategoryStyle={blogCategoryStyle}
      />
    )
  }

  function renderRelatedSection() {
    if (!blogPost.relatedBlogPosts) {
      return null
    }

    return (
      <div className={styles.related}>
        <Container className={styles.slimContainer}>
          <h2>{t.translate(t.KEYS.RELATED_ARTICLES_SECTION_TITLE, locale)}</h2>
        </Container>
        <Container>
          <Row>{renderArticles(blogPost.relatedBlogPosts)}</Row>
        </Container>
      </div>
    )
  }

  function renderArticles(articles) {
    return articles.map(article => {
      const path = getArticlePath(article, locale, defaultLocale)
      return (
        <Col key={article.id} sm={6} md={4}>
          <div className={styles.relatedArticle}>
            <a href={path} className={styles.relatedMedia}>
              {article.thumbnail && (
                <GatsbyImage
                  image={article.thumbnail.gatsbyImageData}
                  alt={article.thumbnail.description}
                />
              )}
            </a>
            <a href={path} className={styles.title}>
              <h6>{article.title}</h6>
            </a>
          </div>
        </Col>
      )
    })
  }

  const templateExtraTags =
    template && template.headTags
      ? renderTags(locale, defaultLocale, template.headTags)
      : null
  const doesPageContainsNoIndexTag =
    doesTagsContainNoIndex(page.extraTags) ||
    doesTagsContainNoIndex(blogPost.extraTags)
  const pageExtraTags = renderTags(locale, defaultLocale, page.extraTags)
  const articleExtraTags = renderTags(locale, defaultLocale, blogPost.extraTags)

  function getRatingValue() {
    const max = 5
    const min = 4.2
    const random = Math.random() * (max - min) + min
    return random.toFixed(1)
  }
  function getRatingCount() {
    const max = 18
    const min = 1
    const random = Math.random() * (max - min) + min
    return random.toFixed(0)
  }

  const ldJson = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "CreativeWorkSeries",
    name: blogPost.title,
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: getRatingValue(),
      bestRating: "5",
      ratingCount: getRatingCount()
    }
  })
  const pageStartScriptTags = renderTags(
    locale,
    defaultLocale,
    page.bodyStartScripts
  )
  const pageEndScriptTags = renderTags(
    locale,
    defaultLocale,
    page.bodyEndScripts
  )
  const hasFixedMenu = _.defaultTo(_.get(page, "fixedMenu"), true)

  // remove the og:image meta tag so that we can override it with the article's main image
  const filteredTemplateExtraTags = _.filter(
    templateExtraTags,
    function (item) {
      return item.type !== "meta" && item.props.property !== "og:image"
    }
  )

  const htmlLang = getHTMLLang(locale)

  const stringReplacements = {
    year: new Date().getFullYear()
  }

  const title = stringReplace(
    _.get(blogPost, "metaTitle", ""),
    stringReplacements
  )

  const metaDescription = stringReplace(
    _.get(blogPost, "metaDescription.metaDescription", ""),
    stringReplacements
  )

  const pageUrl = `${props.data.site.siteMetadata.siteUrl}${_.get(
    props,
    "pageContext.pageUrl",
    ""
  )}`
  const languageCode = getLocaleLanguageCode(locale)

  let imageMetaTags = null

  if (isValidImage(blogPost.mainImage)) {
    imageMetaTags = [
      <meta
        key="og-image"
        property="og:image"
        content={blogPost.mainImage.file.url}
      />,
      <meta
        key="twitter-image"
        property="twitter:image"
        content={blogPost.mainImage.file.url}
      />
    ]
  }

  const pageData = getPageData(
    locale,
    {
      pagePerimeter: _.get(page, "pagePerimeter", ""),
      pagePublication: blogPost.segmentUpdatedAt,
      pageFeatureSector: _.get(page, "pageFeatureSector", "")
    },
    page.slug,
    props.path
  )

  return (
    <Layout locale={locale} defaultLocale={defaultLocale}>
      <SEO
        description={metaDescription}
        lang={htmlLang}
        title={title}
        url={pageUrl}
        includeSelfCanonical={!doesPageContainsNoIndexTag}
      />

      <Helmet>
        <script type="text/javascript">{`${pageData}`}</script>
        {filteredTemplateExtraTags}
        {imageMetaTags}
        {pageExtraTags}
        {articleExtraTags}
        <link rel="alternate" href={pageUrl} hrefLang={languageCode} />
        {categorySchema(
          blogPost,
          blogPost.category,
          pageUrl,
          blogPost.publishedOnSchemaDate,
          blogPost.updatedOnSchemaDate
        )}
      </Helmet>
      <Template
        {...page.template}
        showTopBanner={languageCode === "de"}
        isMenuFixed={hasFixedMenu}
        localeLinks={localeLinks}
        locale={locale}
        defaultLocale={defaultLocale}
      >
        {pageStartScriptTags}
        <div className={styles.progressBar}>
          <ProgressBar color="#0C9EFF" height={4} />
        </div>
        <div className={styles.article}>
          <Breadcrumb
            links={breadcrumb}
            toggleIconDirectionOnSmallDevices={true}
          />
          {renderBody()}
          {renderRelatedSection()}
        </div>
        {pageEndScriptTags}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: ldJson }}
        />
      </Template>
    </Layout>
  )
}

Article.propTypes = {
  path: PropTypes.string.isRequired,
  data: PropTypes.object,
  pageContext: PropTypes.object
}

export default Article
