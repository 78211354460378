import React from "react"
import PropTypes from "prop-types"
import t from "../../translations"

import styles from "./ContentTable.module.scss"

const ContentTable = ({ className, headings, locale }) => {
  return (
    <div className={[className, styles.contentTable].join(" ")}>
      <div className={styles.inner}>
        <div className={styles.contentWrapper}>
          <p>{t.translate(t.KEYS.TABLE_OF_CONTENT, locale)}</p>
          {headings.map((heading, index) => {
            return (
              <a
                key={index}
                href={`#${heading.id}`}
                className={[
                  styles.link,
                  styles[`depth${heading.depth - 1}`]
                ].join(" ")}
              >
                {heading.title}
              </a>
            )
          })}
        </div>
      </div>
    </div>
  )
}

ContentTable.propTypes = {
  className: PropTypes.string,
  headings: PropTypes.array.isRequired,
  locale: PropTypes.string.isRequired
}

export default ContentTable
